import get from 'dlv'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { CACHE_KEY_CANCEL_SETTINGS } from '~/bb-api/constants'
import { GET_CANCEL_SETTINGS } from '~/bb-api/endpoints'
import { CancelSettings } from '~/bb-api/schemata'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'

export default function useCancelSettings() {
  const user = useContext(UserContext)
  const userId = get(user, 'id')
  const endPoint = GET_CANCEL_SETTINGS(userId)

  return useQuery(
    CACHE_KEY_CANCEL_SETTINGS,
    () =>
      axios.get(endPoint).then((response) => response.data as CancelSettings),
    { enabled: !!userId }
  )
}
