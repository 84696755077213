import { formatPriceWithoutTrailingZeroes as fmt } from '~/utils'
import type { ForLifeOffer } from './ForLifeOffer.types'
const BACON_FOR_LIFE_PRICE = 100

const BACON_FOR_LIFE: ForLifeOffer = {
  offerGroupId: 'bacon_for_life',
  richText: {
    content: {
      raw: `{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Members can't get enough of our delicious uncured, sugar-free bacon. For just ${fmt(
        BACON_FOR_LIFE_PRICE
      )}, you'll get a 10 oz. pack in every box for the life of your membership. Never run out of bacon again!","marks":[],"data":{}}]}]}`,
      references: [],
    },
    __typename: 'ContentfulAtomRichText',
  },
  cta: {
    ctaText: 'Claim Now',
    rejectText: 'I don’t want this offer',
  },
  bannerText: `${fmt(BACON_FOR_LIFE_PRICE)} Bacon for Life`,
  product: {
    maxQuantity: 1,
    description: 'Bacon for Life',
    fullDescription: 'Bacon for Life',
    image: '',
    meatGroup: 'pork',
    packSize: '',
    sku: '456411',
    price: BACON_FOR_LIFE_PRICE,
    isUnavailable: false,
  },
  toast: {
    success: 'Bacon for Life has been added to your box!',
    error:
      'There was an issue adding your offer. Please try again or contact customer service to continue.',
  },
}

export const OFFERS: { [key: string]: ForLifeOffer } = {
  bacon_for_life: BACON_FOR_LIFE,
}
