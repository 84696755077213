import { useNavigate } from '@reach/router'
import React from 'react'
import { LoadingPanel } from '~/components/CancelFlowPanels/LoadingPanel/LoadingPanel'
import useUIPrompts from '~/components/CancelFlowPanels/NavigationPanel/hooks/useUIPrompts'
import { SubscriptionContext } from '~/context/subscription'
import useCancelSettings from '~/hooks/useCancelSettings'
import type { CancelPanelProps } from '../CancelFlowPanel.types'
import GenericCancelPanel from '../GenericCancelPanel/GenericCancelPanel.ui'

function shuffle(array) {
  let currentIndex = array.length
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }
  return array
}
/**
 * Navigation Panel within the Cancel Flow
 */
const NavigationPanel: React.FC<CancelPanelProps> = ({ panel }) => {
  const { subscription } = React.useContext(SubscriptionContext)
  const [renderReady, setRenderReady] = React.useState(false)
  const { data: cancelSettings, status } = useCancelSettings()
  const navigate = useNavigate()

  let presentationPrompts = useUIPrompts(
    panel.cancelFlowPrompts,
    cancelSettings,
    subscription,
    navigate
  )

  // Optionally Shuffle the presentation prompts
  if (panel.randomizePromptOrder) {
    presentationPrompts = shuffle(presentationPrompts)
  }

  // Determine if we are ready to render
  React.useEffect(() => {
    setRenderReady(status === 'success' && presentationPrompts.length != 0)
  }, [presentationPrompts, status])

  return renderReady ? (
    <GenericCancelPanel
      modalOptions={presentationPrompts}
      panel={panel}
      showStopOption={false}
    />
  ) : (
    <LoadingPanel />
  )
}

export default NavigationPanel
