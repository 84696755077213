import { Box, Flex, FlexProps } from '@chakra-ui/core'
import { rem } from 'design'
import { ModalContent } from 'design/components/Modal/Modal'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import AtomImage from '~/cms/components/AtomImage/AtomImage'
import { CMS } from '~/cms/types'
import { ValidPaths } from '~/components/CancelFlowModals/constants'
import ModalItem, { IModalItem } from '~/components/ModalItem'
import { TEST_ID } from '~/constants/cypress'
import * as Style from './PanelCancelLayout.css'

type PanelCancelOptionStatus =
  | 'loading'
  | 'idle'
  | 'error'
  | 'success'
  | 'disabled'

export interface PanelCancelOptionNavigate {
  urlDestination: ValidPaths
  text: string
  status?: PanelCancelOptionStatus
  'data-cy'?: TEST_ID
}

export interface PanelCancelOptionOnClick {
  onClick: () => void
  text: string
  status?: PanelCancelOptionStatus
  'data-cy'?: TEST_ID
}

export type PanelCancelOption =
  | PanelCancelOptionOnClick
  | PanelCancelOptionNavigate

export type IPanelLayout = {
  backgroundStyles?: {
    backgroundSize?: string
    backgroundWidth?: number
  }
  image?: CMS.AtomImage

  /**
   * ContentWrapper is a default margin applied to most cancel flow frames.
   * Pass null to render the contents flush with the container.
   */
  ContentWrapper?: typeof PanelContentWrapper | null
} & FlexProps

const PanelLayout: React.FC<IPanelLayout> = ({
  ContentWrapper = PanelContentWrapper,
  children,
  image,
  ...props
}) => {
  const query = useStaticQuery(
    graphql`
      query {
        panelImage: contentfulAtomImage(
          uniqueId: { eq: "cancel-flow-panel-image" }
        ) {
          ...AtomImage
        }
      }
    `
  )
  return (
    <>
      <AtomImage
        aspectRatio="16:9"
        className={!image && Style.PanelImage}
        image={image || query.panelImage}
      />
      <Box
        background="inherit"
        d={{ base: 'block', tablet: 'none' }}
        minHeight={rem(PANEL_MOBILE_TOOLBAR_MIN_HEIGHT)}
      />
      <ModalContent
        data-cy={TEST_ID.CANCEL_FLOW_PANEL}
        height={{ base: '100vh', tablet: 'auto' }}
        width={{ base: '100%', tablet: '67%' }}
        {...props}
      >
        {ContentWrapper ? (
          <ContentWrapper>
            {children}
            <Spacer />
          </ContentWrapper>
        ) : (
          children
        )}
      </ModalContent>
    </>
  )
}

const Spacer = () => (
  <Box
    aria-hidden
    d={{ base: 'block', tablet: 'none' }}
    height={rem(24)}
    width="100%"
  >
    &nbsp;
  </Box>
)

export const PANEL_IMAGE_PERCENT_WIDTH = 33
export const PANEL_MOBILE_TOOLBAR_MIN_HEIGHT = 50
export const PANEL_IMAGE_MAX_HEIGHT = 214
export const HIGH_ZOOM_BREAK_HEIGHT = 500

const ContentWrapperProps = {
  flexDirection: 'column',
  flexGrow: 1,
  p: {
    base: `${rem(5)} ${rem(24)} ${rem(24)}`,
    tablet: `${rem(48)} ${rem(40)}`,
  },
} as FlexProps

/**
 * For simple panels, add padding that will allow the user to scroll content
 * with bottom padding respected.
 */
export const PanelContentWrapper: React.FC<FlexProps> = (props) => (
  <Flex {...ContentWrapperProps} {...props}>
    {props.children}
  </Flex>
)

export const PanelContentWrapperCompact: React.FC<FlexProps> = (props) => (
  <Flex
    {...ContentWrapperProps}
    p={{
      base: `${rem(5)} ${rem(24)} ${rem(24)}`,
      tablet: `${rem(40)} ${rem(40)}`,
    }}
    {...props}
  >
    {props.children}
  </Flex>
)

export const ModalItemSmall: React.FC<IModalItem> = ({
  children,
  ...props
}) => (
  <ModalItem
    fontSize={rem(14)}
    height={rem(56)}
    size="sm"
    textAlign="left"
    {...props}
  >
    {children}
  </ModalItem>
)

export default PanelLayout
