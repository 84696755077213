import dayjs from 'dayjs'
import { graphql, useStaticQuery } from 'gatsby'
import { isNull, isEmpty } from 'lodash'
import { CMS } from '~/cms/types'
import { OFFERS } from '../ForLifeOffer.constants'
import * as TYPES from '../ForLifeOffer.types'

const useCancelFlowOffer: TYPES.useCancelFlowOfferHook = (offerCollection) => {
  const imageData = useStaticQuery(graphql`
    query {
      panelImage: contentfulAtomImage(uniqueId: { eq: "bacon-for-life" }) {
        ...AtomImage
      }
    }
  `)

  const fallbackOffer = {
    ...OFFERS['bacon_for_life'],
    image: imageData.panelImage,
  }

  if (isNull(offerCollection)) return fallbackOffer

  const validOffers = offerCollection.offers.filter((offer) => {
    const now = dayjs()
    return now.isBefore(dayjs(offer.offer.expiration))
  })

  return !isEmpty(validOffers)
    ? getCancelFlowOffer(validOffers[0])
    : fallbackOffer
}

const getCancelFlowOffer = (offer: CMS.OfferMerchandising) => {
  const offerEndDate = dayjs(offer.offer.expiration).format('MMMM D, YYYY')
  return {
    offerGroupId: 'cancel_flow_save_offers',
    richText: offer.snippet.richText,
    cta: {
      ctaText: offer.primaryCta.content,
      rejectText: 'No thanks, I don’t want this offer',
    },
    bannerText: offer.offer.bannerText,
    disclaimerText: `*${offer.snippet.headings[0].heading} will be automatically added to every box ordered until ${offerEndDate}. 
    Cannot be combined with any other offer. Offer valid in contiguous U.S. only. 
    Offer valid until ${offerEndDate} or while supplies last. ButcherBox reserves the right to cancel this offer at 
    any time in its sole discretion and substitute another promotion in its place.`,
    product: {
      maxQuantity: 1,
      description: offer.offer.bannerText,
      fullDescription: offer.offer.bannerText,
      image: '',
      meatGroup: undefined,
      packSize: '',
      sku: `${offer.offer.offerSku}`,
      price: undefined,
      isUnavailable: false,
    },
    image: offer.snippet.image,
    toast: {
      success: `${offer.offer.bannerText} has been added to your box!`,
      error:
        'There was an issue adding your offer. Please try again or contact customer service to continue.',
    },
  }
}
export default useCancelFlowOffer
