import { IconArrowLeft, rem } from '@butcherbox/freezer'
import { Box } from '@chakra-ui/core'
import { LocationProvider, RouteComponentProps, Router } from '@reach/router'
import { Modal } from 'design/components/Modal/Modal'
import { TABLET_MIN_WIDTH } from 'design/theme'
import React from 'react'
import {
  trackCancellationAborted,
  trackCancellationStarted,
  trackCancellationStepViewed,
} from '~/analytics/events'
import { ValidPaths } from '~/components/CancelFlowModals/constants'
import ModalCancelBase from '~/components/CancelFlowModals/ModalCancelBase'
import {
  HIGH_ZOOM_BREAK_HEIGHT,
  PANEL_IMAGE_MAX_HEIGHT,
  PANEL_IMAGE_PERCENT_WIDTH,
  PANEL_MOBILE_TOOLBAR_MIN_HEIGHT,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import NavigationPanel from '~/components/CancelFlowPanels/NavigationPanel/NavigationPanel'
import { ReachRouterHistoryProvider } from '~/context/reachRouterHistory'
import { ReportAnIssueProvider } from '~/context/reportAnIssue'
import useWindowSize from '~/design/hooks/useWindowSize'
import useReachMemorySource from '~/hooks/useReachMemorySource'
import { logoutToHome } from '~/utils/auth0'
import PanelForLifeOffer from '../CancelFlowPanels/ForLifeOffer/ForLifeOffer'
import useActionPanel from './hooks/useActionPanel'
import useCmsCancelPanels from './hooks/useCmsCancelPanels'
import useCustomerCanReceiveOffer from './hooks/useCustomerCanReceiveOffer'

const ICON_OFFSET_MOBILE = 24
const ICON_OFFSET_TABLET = 16
// Button size offset is to allow the buttons to have a larger area for clicking/tapping
// without changing the size of the icons
const BUTTON_SIZE_OFFSET = 10

interface ModalCancellationFlowProps extends RouteComponentProps {
  handleModalClose: () => void
}

const ModalCancellationFlow: React.FC<ModalCancellationFlowProps> = ({
  handleModalClose,
}) => {
  const { history, historyStack } = useReachMemorySource<ValidPaths>()
  const { innerHeight, innerWidth } = useWindowSize()
  const [buttonTopOffset, setButtonTopOffset] = React.useState(
    PANEL_IMAGE_MAX_HEIGHT
  )

  // Track start of the cancel flow
  React.useEffect(() => {
    trackCancellationStarted()
  }, [])

  // Listen to changes in history
  // Track the step viewed based on the path provided.
  React.useEffect(() => {
    return history.listen((event) => {
      const path = event.location.pathname
      trackCancellationStepViewed(path as ValidPaths)
    })
  }, [history])

  // This is needed to reposition the back and close buttons at high zoom levels,
  // the modal image gets hidden in PanelCancelLayout.
  // When at smaller screen widths the image heigh is less than the max height
  // causing the back button to be too low, so at smaller widths we lower the offset.
  React.useEffect(() => {
    if (
      (innerWidth || 0) < TABLET_MIN_WIDTH &&
      (innerHeight || 0) < HIGH_ZOOM_BREAK_HEIGHT
    ) {
      setButtonTopOffset(0)
    } else if (innerWidth < 385) {
      setButtonTopOffset(PANEL_IMAGE_MAX_HEIGHT - 50)
    } else if (innerWidth < 430) {
      setButtonTopOffset(PANEL_IMAGE_MAX_HEIGHT - 40)
    } else if (innerWidth < 500) {
      setButtonTopOffset(PANEL_IMAGE_MAX_HEIGHT - 20)
    } else {
      setButtonTopOffset(PANEL_IMAGE_MAX_HEIGHT)
    }
  }, [innerHeight, innerWidth, buttonTopOffset])

  // Is the current user eligible for the current offer
  const customerCanReceiveOffer = useCustomerCanReceiveOffer()
  const [showForLifeOfferModal, setShowForLifeOfferModal] = React.useState(
    customerCanReceiveOffer
  )
  const panels = useCmsCancelPanels()

  // The HomePanel is unique and fixed based on the MultiStageFlow
  // We assume it's a navigation panel, but in the event it's an action panel,
  // we account for that here.
  let HomePanel
  if (panels.home.cancelFlowPrompts.length > 0) {
    HomePanel = NavigationPanel
  } else {
    HomePanel = useActionPanel(panels.home)
  }

  const AreYouSure = useActionPanel(panels.areYouSure)

  return (
    // Location provider gives modals the memory history rather than browser history
    <LocationProvider history={history}>
      {({ location }) => {
        return (
          <Modal
            closeButtonProps={{
              // @ts-ignore
              color: {
                base: 'white',
                tablet: showForLifeOfferModal ? '#fff' : 'bb.stone',
              },
              top: {
                base: `calc(${rem(
                  buttonTopOffset - BUTTON_SIZE_OFFSET
                )} + ${rem(PANEL_MOBILE_TOOLBAR_MIN_HEIGHT)} / 2)`,
                tablet: rem(ICON_OFFSET_TABLET),
              },
              right: {
                base: rem(ICON_OFFSET_MOBILE - BUTTON_SIZE_OFFSET),
                tablet: rem(ICON_OFFSET_TABLET),
              },
              height: rem(30),
              width: rem(30),
            }}
            data-what="cancel-flow-modal"
            maxModalHeight={100}
            maxModalWidth={100}
            onClose={() => {
              showForLifeOfferModal
                ? setShowForLifeOfferModal(false)
                : handleModalClose()
              if (location.pathname.includes('/confirmed-stopped')) {
                logoutToHome()
              } else {
                trackCancellationAborted()
              }
            }}
          >
            {showForLifeOfferModal ? (
              <PanelForLifeOffer
                handleModalClose={handleModalClose}
                handleRejectOffer={() => {
                  setShowForLifeOfferModal(false)
                }}
              />
            ) : (
              <>
                <ReachRouterHistoryProvider historyStack={historyStack}>
                  <ReportAnIssueProvider>
                    <ModalCancelBase>
                      <Router
                        basepath="/"
                        style={{ display: 'contents', height: 'inherit' }}
                      >
                        <HomePanel
                          default={true}
                          panel={panels.home}
                          path="/"
                        />
                        <AreYouSure
                          handleModalClose={handleModalClose}
                          panel={panels.areYouSure}
                          path="/are-you-sure"
                        />
                        {panels.navigation.map((p, index) => {
                          return (
                            <NavigationPanel
                              key={`${p.panelId}_${index}`}
                              panel={p}
                              path={`/${p.panelId}`}
                            />
                          )
                        })}
                        {panels.action.map((p, index) => {
                          const Component = useActionPanel(p)
                          if (Component) {
                            return (
                              <Component
                                handleModalClose={handleModalClose}
                                key={index}
                                panel={p}
                                path={`/${p.panelId}`}
                              />
                            )
                          }
                        })}
                      </Router>
                    </ModalCancelBase>
                  </ReportAnIssueProvider>
                </ReachRouterHistoryProvider>
                {location.pathname !== '/' &&
                  !location.pathname.includes('/confirmed-stopped') && (
                    <Box
                      alignItems="center"
                      as="button"
                      display="flex"
                      height={rem(30)}
                      justifyContent="center"
                      left={{
                        base: `${rem(ICON_OFFSET_MOBILE - BUTTON_SIZE_OFFSET)}`,
                        tablet: `calc(${PANEL_IMAGE_PERCENT_WIDTH}% + ${rem(
                          ICON_OFFSET_TABLET
                        )})`,
                      }}
                      onClick={() => history.navigate(-1)}
                      paddingBottom={{ base: rem(20), desktop: rem(0) }}
                      position="absolute"
                      top={{
                        base: `calc(${rem(
                          buttonTopOffset - BUTTON_SIZE_OFFSET
                        )} + ${rem(PANEL_MOBILE_TOOLBAR_MIN_HEIGHT)} / 2)`,
                        tablet: rem(ICON_OFFSET_TABLET),
                      }}
                      width={rem(30)}
                    >
                      <IconArrowLeft
                        customColor={{ base: 'stone' }}
                        size="text"
                        title="Back one step"
                      />
                    </Box>
                  )}
              </>
            )}
          </Modal>
        )
      }}
    </LocationProvider>
  )
}

export default ModalCancellationFlow
