import React from 'react'
import { CMS } from '~/cms/types'
import useOptimizelyExperiment from './useOptimizelyExperiment'

type CMSVariant =
  | CMS.VariationContainer
  | CMS.Hero
  | CMS.CTA
  | CMS.ModuleOptIn
  | CMS.ResourceSet
  | CMS.NarrativeHero
  | CMS.NarrativeCopy
  | CMS.CancelFlowPrompt
  | CMS.CancelFlowPanel
  | CMS.LongCTA

type CMSVariantResult =
  | CMS.Hero
  | CMS.CTA
  | CMS.ModuleOptIn
  | CMS.ResourceSet
  | CMS.NarrativeHero
  | CMS.NarrativeCopy
  | CMS.CancelFlowPrompt
  | CMS.CancelFlowPanel
  | CMS.LongCTA
  | null

export default function useCmsVariant<T extends CMSVariantResult>(
  obj: CMSVariant
): T {
  if (!obj) return null
  const [variant] = useOptimizelyExperiment(
    (obj as CMS.VariationContainer)?.experimentKey ?? 'unknown'
  )

  return React.useMemo(() => {
    switch (obj.__typename) {
      case 'ContentfulVariationContainer':
        const result =
          obj.variations.find((v) => v.contentful_id === obj.meta[variant]) ||
          obj.variations[0]
        return result as T
      default:
        return obj as T
    }
  }, [variant, obj])
}
