import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import { ModalItemSmall } from '~/components/CancelFlowModals/PanelCancelLayout'
import { useNavigate } from '@reach/router'

export const CONFIRM_CANCEL_ROUTE = 'are-you-sure'
/**
 * This is the CTA that drives members to the /are-you-sure
 * panel where users can submit their intent to cancel
 */
function StopMySubscriptionButton() {
  const navigate = useNavigate()
  return (
    <ModalItemSmall
      data-cy={TEST_ID.CANCEL_FLOW_STOP_SUBSCRIPTION}
      onClick={() => navigate(`/${CONFIRM_CANCEL_ROUTE}`)}
    >
      No, stop my subscription
    </ModalItemSmall>
  )
}

export default StopMySubscriptionButton
