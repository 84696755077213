import { rem } from 'design'
import ModalCard, { IModalCard } from 'design/components/ModalCard/ModalCard'
import { FieldProps } from 'formik'
import React from 'react'
import { ModalItemFlagType } from '~/components/ModalItemFlag'

export type IModalItem = {
  size?: 'sm' | 'lg'
  Flag?: ModalItemFlagType
  type?: 'button' | 'submit'
} & IModalCard &
  Partial<FieldProps['field']>

/**
 * Wrapper component for more specialized modal items. Compose this component
 * and render children within it to show various different types of modal items.
 */
const ModalItem: React.FC<IModalItem> = ({
  children,
  size = 'lg',
  Flag,
  ...props
}) => {
  return (
    <ModalCard
      alignItems={size === 'sm' ? 'left' : 'center'}
      maxWidth={{ base: '100%', tablet: rem(348) }}
      p={rem(16)}
      {...props}
    >
      {Flag}
      {children}
    </ModalCard>
  )
}

export default ModalItem
