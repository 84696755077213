import { Box, Button, Text } from '@butcherbox/freezer'
import { RouteComponentProps, Router } from '@reach/router'
import dayjs, { Dayjs } from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { pluralize } from 'design'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import ModalCancellationFlow from '~/components/ModalCancellationFlow/ModalCancellationFlow'
import { TEST_ID } from '~/constants/cypress'
import { SubscriptionContext } from '~/context/subscription'
import { mutateSubscription } from '~/hooks/mutateSubscription'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'
import { RouterPage } from '~/utils/route'
import * as Styles from './AccountDelayCancelFlow.css'

dayjs.extend(isSameOrBefore)

const delayOptions: { number: number; unit: dayjs.OpUnitType }[] = [
  { number: 2, unit: 'week' },
  { number: 4, unit: 'week' },
  { number: 6, unit: 'week' },
]

const CANCEL_SUBSCRIPTION = 'cancel-subscription'

const AccountDelayCancelFlow: React.FC<RouteComponentProps> = ({
  navigate,
}) => {
  const { subscription } = React.useContext(SubscriptionContext)
  const currentBillDate = subscription.periodEndDate
  const showToast = React.useContext(ToastContext)
  const [updateSubscription, { status }] = mutateSubscription('delay_flow')
  const { data: upcomingOrder } = useUpcomingOrder()
  const [activeButtonIndex, setActiveButtonIndex] = React.useState(-1)

  const handleModalClose = React.useCallback(
    () => navigate('/account/box-settings'),
    [navigate]
  )

  const addTimeToBillDate = (num: number, unit: dayjs.OpUnitType): Dayjs => {
    return dayjs(currentBillDate).add(num, unit)
  }

  const hasExpiringDeals = React.useMemo(() => {
    const sixWeeksFromBillDate = dayjs(currentBillDate).add(6, 'w')
    return upcomingOrder?.invoiceItems.some((invoiceItem) => {
      if (!!invoiceItem?.created_at) {
        return invoiceItem?.created_at.some((createdAt) => {
          return dayjs(createdAt.expire_on).isSameOrBefore(sixWeeksFromBillDate)
        })
      } else {
        return false
      }
    })
  }, [currentBillDate, upcomingOrder])

  const updateBillDate = React.useCallback(
    (newBillDate: Dayjs, buttonIndex: number) => {
      if (status === 'loading') return
      setActiveButtonIndex(buttonIndex)

      return updateSubscription(
        {
          ...subscription,
          periodEndDate: newBillDate.format('YYYY-MM-DD'),
        },
        {
          onSuccess: () => {
            showToast('success', {
              children: 'Success! You have updated your bill date.',
            })

            navigate('/account/box-settings')
          },
          onError: () => {
            showToast('error', {
              children:
                'There was an error updating your bill date. Please try again.',
            })
          },
          onSettled: () => {},
        }
      )
    },
    [navigate, showToast, status, subscription, updateSubscription]
  )
  const filteredDelayOptions = delayOptions.filter(({ number, unit }) => {
    return addTimeToBillDate(number, unit).isBefore(dayjs().add(6, 'month'))
  })

  return (
    <Box className={Styles.CancelFlowContainer}>
      <Box className={Styles.CancelFlowContentContainer}>
        <Box paddingBottom={16}>
          <Text variant="H1Bold">Want to delay your shipment?</Text>
        </Box>
        <Box marginBottom={24}>
          <Box paddingBottom={16}>
            <Text variant="H3Bold">
              Your next ButcherBox is scheduled to bill on{' '}
              {dayjs(currentBillDate).format('MMM D, YYYY')}. You may delay your
              shipment below.
            </Text>
          </Box>

          {hasExpiringDeals ? (
            <Text variant="H3Bold">
              Note: delaying your box may remove some of your member deals.
            </Text>
          ) : null}
        </Box>
        <Box marginBottom={8}>
          {filteredDelayOptions.map(({ number, unit }, idx) => {
            const newBillDate = addTimeToBillDate(number, unit)
            return (
              <Button
                data-cy={TEST_ID.DELAY_BOX_BUTTON}
                data-cy-box-delay={number}
                data-new-bill-date={dayjs(newBillDate).format('YYYY-MM-DD')}
                data-prev-bill-date={dayjs(currentBillDate).format(
                  'YYYY-MM-DD'
                )}
                data-what={`delay_${number}_weeks_button`}
                disabled={status === 'loading' && activeButtonIndex !== idx}
                key={number}
                loading={idx === activeButtonIndex && status === 'loading'}
                marginBottom={16}
                onClick={() => updateBillDate(newBillDate, idx)}
                size="standard"
                variant="primary"
              >{`Delay my box by ${number} ${pluralize(number, unit)}`}</Button>
            )
          })}
        </Box>
        <Button
          data-cy={TEST_ID.CANCEL_FLOW_TRIGGER}
          data-what="delay_no_thanks_link"
          onClick={() => navigate(`${CANCEL_SUBSCRIPTION}`)}
          variant="text"
        >
          No thanks, show me other options.
        </Button>
      </Box>
      <Router>
        <RouterPage
          path={`${CANCEL_SUBSCRIPTION}/*`}
          renderRoute={(props) => (
            <ModalCancellationFlow
              handleModalClose={handleModalClose}
              {...props}
            />
          )}
        />
      </Router>
    </Box>
  )
}

export default AccountDelayCancelFlow
