import { VisuallyHidden } from '@chakra-ui/core'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import PanelLayout from '~/components/CancelFlowModals/PanelCancelLayout'

/**
 * Presents a Cancellation Panel with a Spinner
 */
export const LoadingPanel = () => {
  return (
    <PanelLayout>
      <LoadingSpinner m="auto" size={'regular'} />
      {/* TODO: Adding here for now, but I think it may be best in the future to do some refactoring and make the modal itself `aria-live="polite"` and `aria-busy` when loading content */}
      <VisuallyHidden aria-live="polite">Loading...</VisuallyHidden>
    </PanelLayout>
  )
}
