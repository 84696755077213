import React from 'react'
import { UpcomingInvoiceItem } from '~/bb-api/schemata'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'

/**
 * This hook determines if the user is eligible for the current for life
 * offer, this is hardcoded here to be 'bacon_for_life' but could be derived
 * from the api or contentful in the future.
 */
const OFFER_GROUP = 'cancel_flow_save_offers'

const checkProductArrayHasOffer = (productArray: UpcomingInvoiceItem[]) => {
  return (
    productArray?.filter((product) => product.offerGroup === OFFER_GROUP)
      .length > 0
  )
}

const useCustomerCanReceiveOffer = () => {
  const { data: upcomingOrder } = useUpcomingOrder()
  // TODO: Investigate issue where subscription context doesn't have offer when using macro.
  return React.useMemo(() => {
    if (!!window?.Cypress || !upcomingOrder) {
      return false
    }

    if (checkProductArrayHasOffer(upcomingOrder?.invoiceItems)) {
      return false
    }

    if (checkProductArrayHasOffer(upcomingOrder?.subscription.box.offers)) {
      return false
    }

    return true
  }, [upcomingOrder])
}

export default useCustomerCanReceiveOffer
