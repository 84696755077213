import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import makeRichTextOptions from '~/cms/rich-text'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { CMS } from '~/cms/types'
import {
  Text,
  ListItem,
  List,
  TextLink,
  Color,
  TextVariants,
  TextLinkProps,
  Lockup,
} from '@butcherbox/freezer'
import {
  getNewBrandColorName,
  getNewBrandLinkTheme,
} from '../ModuleMarketingCopy/utils/color'
import { OldColor, OldTextLinkThemes } from 'design/colors'

export interface AtomRichTextProps {
  richText: CMS.AtomRichText
  config: {
    textColor?: Color | OldColor
    paragraphStyle: TextVariants
    linkTheme?: TextLinkProps['theme'] | OldTextLinkThemes
  }
  /**
   * Should this be wrapped in a lockup to provide spacing.
   */
  useLockup?: boolean
}

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children

const AtomRichText = ({
  richText,
  config,
  useLockup = true,
}: AtomRichTextProps) => {
  if (!richText) return null

  // ensure that link theme uses new branding color name
  const safeLinkTheme = config.linkTheme
    ? getNewBrandLinkTheme(config.linkTheme)
    : 'spicedCrimson'

  // ensure that text color uses new branding color name
  const safeTextColor = config.textColor
    ? getNewBrandColorName(config.textColor)
    : 'inherit'
  return (
    <ConditionalWrap
      condition={useLockup}
      wrap={(children) => <Lockup>{children}</Lockup>}
    >
      {renderRichText(
        richText.content,
        makeRichTextOptions({
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_, children) => (
              <Text
                color={safeTextColor}
                component="p"
                variant={config.paragraphStyle}
              >
                {children}
              </Text>
            ),
            [BLOCKS.OL_LIST]: (_, children) => (
              <List variant="ordered">{children}</List>
            ),
            [BLOCKS.UL_LIST]: (_, children) => (
              <List variant="unordered">{children}</List>
            ),
            [BLOCKS.LIST_ITEM]: (_, children) => (
              <ListItem>{children}</ListItem>
            ),
            // TODO - need some values in the CMS to work with and play with the rendering output
            // to determine which theme to provide to the link style.
            [INLINES.HYPERLINK]: ({ data }, children) => (
              <TextLink href={data.uri} theme={safeLinkTheme} underline={true}>
                {children}
              </TextLink>
            ),
          },
        })
      )}
    </ConditionalWrap>
  )
}

export default AtomRichText
