import { HistorySource, WindowLocation } from '@reach/router'

interface IMemorySource extends HistorySource {
  history: HistorySource['history'] & {
    entries: WindowLocation[]
    go(index: number): void
    index: number
  }
}

/* Modified version of Reach's createMemorySource to fix back behavior and state passing
https://github.com/reach/router/blob/e203fe612236851a0bd3c77dba693e539a351c62/src/lib/history.js#L103-L152 */
const createReachMemorySource = (initialPath = '/'): IMemorySource => {
  const searchIndex = initialPath.indexOf('?')
  const initialLocation = {
    pathname:
      searchIndex > -1 ? initialPath.substr(0, searchIndex) : initialPath,
    search: searchIndex > -1 ? initialPath.substr(searchIndex) : '',
    state: {},
  } as WindowLocation
  let index = 0
  const stack: WindowLocation[] = [initialLocation]
  const states = [null]

  return {
    get location() {
      return stack[index]
    },
    addEventListener() {},
    removeEventListener() {},
    history: {
      // @ts-ignore
      get entries() {
        return stack
      },
      get index() {
        return index
      },
      get state() {
        return states[index]
      },
      pushState(state, _, uri) {
        const [pathname, search = ''] = uri.split('?')
        index++
        stack.push({
          pathname,
          search: search.length ? `?${search}` : search,
          state,
        } as WindowLocation)
        states.push(state)
      },
      replaceState(state, _, uri) {
        const [pathname, search = ''] = uri.split('?')
        stack[index] = { pathname, search, state } as WindowLocation
        states[index] = state
      },
      go(to) {
        const newIndex = index + to

        if (newIndex > states.length - 1) {
          return
        }

        if (to === -1) {
          stack.splice(stack.length - 1, 1)
          states.splice(stack.length - 1, 1)
        }

        index = newIndex
      },
    },
  }
}

export default createReachMemorySource
