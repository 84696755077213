import loadable from '@loadable/component'
import type { CMS } from '~/cms/types'

// Provided a panel - lazy load the component return it.
const useActionPanel = (panel: CMS.CancelFlowPanel) => {
  switch (panel.cancelSaveComponent) {
    case 'Change Frequency - All Except Current':
    case 'Change Frequency - Longer than Current':
    case 'Change Frequency - Shorter than Current':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelChangeFrequency/PanelChangeFrequency'
          )
      )
    case 'Change Size - Increase':
    case 'Change Size - Decrease':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelChangeSize/PanelChangeSize'
          )
      )
    case 'Confirm Cancellation':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelAreYouSure/PanelAreYouSure'
          )
      )
    case 'Confirm Cancellation - Lose Offers':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelAreYouSure/PanelAreYouSurePerks'
          )
      )
    case 'Customer Service - Chat':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelCSChat/PanelCSChat'
          )
      )
    case 'Delay - Default':
    case 'Delay - Default - Show Next Bill Date':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelDelay/PanelDelay'
          )
      )
    case 'Delivery or Packaging Issues':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelDeliveryPackagingIssues/PanelDeliveryPackagingIssues'
          )
      )
    case 'Edit Address':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelEditAddress/PanelEditAddress'
          )
      )
    case 'Educate - Defrosting Rules':
    case 'Educate - Grass Fed Beef':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelEducate/PanelEducate'
          )
      )
    case 'How Can We Fix This?':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelHowCanWeFixThis/PanelHowCanWeFixThis'
          )
      )
    case 'Long Text Input':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelOther/PanelOther'
          )
      )
    case 'Offer - $99 Box':
    case 'Offer - Chefs Value Box':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelOfferValueBox/PanelOfferValueBox'
          )
      )
    case 'Offer Credit':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelOfferCredit/PanelOfferCredit'
          )
      )
    case 'Reship Confirmation':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelReshipConfirm/PanelReshipConfirm'
          )
      )
    case 'Select Affected Items - Base':
    case 'Select Affected Items - All':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelSelectAffectedItems/PanelSelectAffectedItems'
          )
      )
    case 'Select Custom Cuts':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelSelectCustomCuts/PanelSelectCustomCuts'
          )
      )
    case 'Stop Subscription':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelStopSubscription/PanelStopSubscription'
          )
      )
    case 'Subscription Stopped':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/StoppedSubscriptionPanel/StoppedSubscriptionPanel'
          )
      )
    case 'Update Account Settings':
      return loadable(
        () =>
          import(
            '~/components/CancelFlowPanels/ActionPanels/PanelUpdateAccountSettings/PanelUpdateAccountSettings'
          )
      )
    default:
      const _exhaustivenessCheck: never = panel.cancelSaveComponent
      return _exhaustivenessCheck
  }
}
export default useActionPanel
