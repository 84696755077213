import React from 'react'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'
import { CMS } from '~/cms/types'
import * as Styles from './AtomImage.css'
import clsx from 'clsx'
import { Box, BoxProps } from '@butcherbox/freezer'

export interface AtomImageProps
  extends Pick<BoxProps, 'py' | 'paddingTop' | 'paddingBottom'> {
  image: CMS.AtomImage
  aspectRatio?: 'Original Image' | '4:3' | '16:9' | '1:1'
  className?: string
  loading?: GatsbyImageProps['loading']
  objectFit?: GatsbyImageProps['objectFit']
  /**
   * Use this property if you want to supersede the
   * value provided by the image
   */
  objectPosition?: GatsbyImageProps['objectPosition']
}

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children

const AtomImage = ({
  image,
  aspectRatio = 'Original Image',
  className,
  loading = 'lazy',
  objectFit = 'cover',
  objectPosition,
  py,
  paddingBottom,
  paddingTop,
}: AtomImageProps) => {
  if (!image) return null
  let wrapBox = false
  let boxProps: BoxProps = {}
  if (py || paddingBottom || paddingTop) {
    boxProps = { py, paddingBottom, paddingTop }
    wrapBox = true
  }
  return (
    <ConditionalWrap
      condition={wrapBox}
      wrap={(children) => <Box {...boxProps}>{children}</Box>}
    >
      <GatsbyImage
        alt={image.description}
        className={clsx(className, Styles.AtomImage[aspectRatio])}
        image={{ ...image.image.gatsbyImageData }}
        loading={loading}
        objectFit={objectFit}
        // By default we want to use the focal point provided by
        // the image, with an option to override that value via the
        // prop interface
        objectPosition={objectPosition ? objectPosition : image.focalPoint}
        style={{
          position: 'relative',
          height: 'auto',
          display: 'block',
        }}
      />
    </ConditionalWrap>
  )
}

export default AtomImage
