import React, { useState } from 'react'
import { BoxItem, Order, OrderIssue } from '~/bb-api/schemata'
import useOrders from '~/hooks/useOrders'

export type IReportAnIssue = {
  affectedOrder: Order
  creditAmount: number
  orderItemsBySku: { [key: string]: BoxItem }
  orderIssue: OrderIssue
  setCreditAmount: Function
  updateOrderIssue: Function
}

const defaultOrderIssue: OrderIssue = {
  items: [],
  issueType: null,
  message: '',
  resolution: null,
}

export const ReportAnIssueContext = React.createContext<IReportAnIssue>(
  undefined
)

export const ReportAnIssueProvider = ({ children }) => {
  const { data: [order] = [], status } = useOrders()
  const [orderIssue, setOrderIssue] = useState(defaultOrderIssue)
  const [creditAmount, setCreditAmount] = useState(0)

  const updateOrderIssue = (newOrderIssue: OrderIssue) => {
    setOrderIssue(newOrderIssue)
  }

  const orderItemsBySku: { [key: string]: BoxItem } = React.useMemo(
    () =>
      order
        ? Array.prototype
            .concat(
              order.box.items,
              order.box.addons,
              order.extras,
              order.invoiceItems
            )
            .reduce((map, current) => ((map[current.sku] = current), map), {})
        : {},
    [order]
  )

  return status === 'success' ? (
    <ReportAnIssueContext.Provider
      value={{
        affectedOrder: order,
        creditAmount,
        orderItemsBySku,
        orderIssue,
        setCreditAmount,
        updateOrderIssue,
      }}
    >
      {children}
    </ReportAnIssueContext.Provider>
  ) : null
}
