import { createHistory, History, HistorySource } from '@reach/router'
import React from 'react'
import createReachMemorySource from '~/utils/createReachMemorySource'

export type IReachMemorySource<AvailablePaths> = {
  history: History
  historyStack: HistoryStack<AvailablePaths>
}

export type HistoryStack<AvailablePaths = string> = {
  pathname: AvailablePaths
  search: string
  state: object
}[]

export default function useReachMemorySource<
  AvailablePaths
>(): IReachMemorySource<AvailablePaths> {
  React.useDebugValue('useReachMemorySource')

  // Using a memory router to prevent linking into deeper parts of cancel flow
  const source = React.useRef<HistorySource>(createReachMemorySource('/'))
  const history = React.useMemo(() => createHistory(source.current), [])

  return {
    history,
    // @ts-ignore
    historyStack: source.current.history.entries as HistoryStack,
  }
}
