// The required routes are used within Action Panels and are expected to exist
// if they are not provided in the contentful payload then we should break the
// build.
export const REQUIRED_ROUTES = [
  'are-you-sure',
  'change-frequency-cant-afford',
  'change-frequency-not-enough',
  'confirmed-stopped',
  'customer-service',
  'delay',
  'delay-prompt',
  'edit-address',
  'increase-size',
  'other',
  'report-issue-select-items-base',
  'report-issue-select-items-select-all',
  'reship-confirm',
]
