import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import {
  cancellationFlowOfferAdded,
  cancellationFlowOfferSeen,
  trackCancellationSave,
} from '~/analytics/events'
import { mutateIncrementInvoiceItem } from '~/hooks/mutateInvoiceItem'
import type { PanelForLifeOfferProps } from './ForLifeOffer.types'
import PanelForLifeOfferUI from './ForLifeOffer.ui'
import useCancelFlowOffer from './hooks/useCancelFlowOffer'

const PanelForLifeOffer: React.FC<PanelForLifeOfferProps> = ({
  handleRejectOffer,
  handleModalClose,
}) => {
  const showToast = React.useContext(ToastContext)

  const query = useStaticQuery(
    graphql`
      query {
        offerCollection: contentfulOfferCollection(
          uniqueId: { eq: "cancel_flow_save_offers" }
        ) {
          ...OfferCollection
        }
      }
    `
  )
  // Get the offer from the hook
  const offer = useCancelFlowOffer(query.offerCollection)

  // Track presentation of the offer
  React.useEffect(() => {
    cancellationFlowOfferSeen(offer.product.sku, offer.product.description)
  }, [offer])

  // Set up callback for successfully adding offer
  const [mutate, { status: mutateStatus }] = mutateIncrementInvoiceItem()

  const updateOffer = React.useCallback(
    () =>
      mutate(offer.product, {
        onError() {
          showToast('error', {
            children: offer.toast.error,
          })
        },
        onSuccess() {
          cancellationFlowOfferAdded(
            offer.product.sku,
            offer.product.description
          )
          trackCancellationSave(CANCEL_SAVE_ACTIONS.OFFER_ADDED)
          showToast('success', {
            children: offer.toast.success,
          })
        },
        onSettled: () => handleModalClose(),
      }),
    [handleModalClose, showToast, offer, mutate]
  )

  return (
    <PanelForLifeOfferUI
      isLoading={mutateStatus === 'loading'}
      offer={offer}
      onClaimHandler={() => updateOffer()}
      onRejectHandler={handleRejectOffer}
    />
  )
}

export default PanelForLifeOffer
