import React from 'react'
import { HistoryStack } from '~/hooks/useReachMemorySource'

type IReachRouterHistoryContext = {
  stack: HistoryStack
  getHistoryEntry: IGetHistoryEntry<{
    pathname: string
    search: string
    state: {
      text?: string
    }
  }>
}

export const ReachRouterHistoryContext = React.createContext<
  IReachRouterHistoryContext
>(undefined)

// @ts-ignore
ReachRouterHistoryContext.Provider.displayName =
  'ReachRouterHistoryContextProvider'

/**
 * Retrieve an entry from the history in the context. Use a negative index to
 * pull from the end of the stack, -1 being the last entry, -2 second last, etc.
 */
export type IGetHistoryEntry<Paths> = (index: number) => Paths // See TODO below

export const ReachRouterHistoryProvider: React.FC<{
  // TODO: It would be awesome to add a type argument to HistoryStack here, so
  //       that the context provider can be instantiated with the valid paths
  //       that will be present in the history. It's unclear though how to
  historyStack: HistoryStack
}> = ({ historyStack, children }) => {
  const getHistoryEntry = React.useCallback<
    IGetHistoryEntry<{ pathname: string; search: string; state: object }>
  >(
    (index = 0) => {
      const stackItem =
        historyStack[index < 0 ? historyStack.length + index : index]
      return stackItem
    },
    [historyStack]
  )

  return (
    <ReachRouterHistoryContext.Provider
      value={{
        stack: historyStack,
        getHistoryEntry,
      }}
    >
      {children}
    </ReachRouterHistoryContext.Provider>
  )
}
