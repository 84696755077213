import { rem } from 'design'
import { ModalDialog } from 'design/components/Modal/Modal'
import React from 'react'
import type { ModalDialogProps } from 'design/components/Modal/Modal.types'

export type ModalCancelBaseProps = ModalDialogProps
const MAX_HEIGHT_SCROLL_Y = 554

const ModalCancelBase = ({ children, ...props }: ModalCancelBaseProps) => {
  return (
    <ModalDialog
      flexDirection={{ base: 'column', tablet: 'row' }}
      height={{ tablet: rem(MAX_HEIGHT_SCROLL_Y) }}
      width={{ base: '100vw', tablet: rem(904) }}
      {...props}
    >
      {children}
    </ModalDialog>
  )
}

export default ModalCancelBase
