import { Box, Button, Lockup, rem, Text } from '@butcherbox/freezer'
import React from 'react'
import AtomRichText from '~/cms/components/AtomRichText/AtomRichText'
import ModalCancelBase from '~/components/CancelFlowModals/ModalCancelBase'
import PanelLayout from '~/components/CancelFlowModals/PanelCancelLayout'
import * as Style from './ForLifeOffer.css'
import type { PanelForLifeOfferUIProps } from './ForLifeOffer.types'

const PanelForLifeOfferUI: React.FC<PanelForLifeOfferUIProps> = ({
  offer,
  isLoading,
  onClaimHandler,
  onRejectHandler,
}) => {
  return (
    <ModalCancelBase>
      <Box
        display="flex"
        flexDirection={{ mobile: 'column', tablet: 'rowReverse' }}
      >
        <PanelLayout
          backgroundStyles={{
            backgroundSize: '120%',
            backgroundWidth: 40,
          }}
          data-what="cancel-flow-for-life-offer-modal"
          data-where="cancel-flow-for-life-offer-modal"
          image={offer.image}
          px={rem(0)}
          width={{ base: '100%', tablet: '60%' }}
        >
          <Box className={Style.container}>
            <Lockup textAlign="center">
              <Text variant="Eyebrow">Limited Time Only</Text>
              <Text component="h2" variant="DisplayTwo">
                {offer.bannerText}
              </Text>

              <AtomRichText
                config={{
                  paragraphStyle: 'Body1Regular',
                  textColor: 'slate',
                }}
                richText={offer.richText}
                useLockup={false}
              />
              <Button
                data-what="cancel-flow-offer-accept"
                loading={isLoading}
                onClick={onClaimHandler}
              >
                {offer.cta.ctaText}
              </Button>
              <Button
                data-what="cancel-flow-offer-reject"
                display="block"
                mx="auto"
                onClick={onRejectHandler}
                variant="text"
              >
                No Thanks, I don't want this offer
              </Button>
              <br />
              <Text variant="Micro">{offer.disclaimerText}</Text>
            </Lockup>
          </Box>
        </PanelLayout>
      </Box>
    </ModalCancelBase>
  )
}

export default PanelForLifeOfferUI
