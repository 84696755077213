import { graphql, useStaticQuery } from 'gatsby'
import { CMS } from '~/cms/types'
import useCmsVariant from '~/hooks/useCmsVariant'
import { REQUIRED_ROUTES } from '../ModalCancellationFlow.constants'

/**
 * This hook fetches the root entry point for the cancel flow,
 * and all published panels, filtering the two groups into action panels
 * and navigation panels.
 */

interface QueryData {
  root: CMS.MultiStageFlow
  areYouSure: CMS.MultiStageFlow
  panels: {
    nodes: Array<CMS.CancelFlowPanel>
  }
}

const useCmsCancelPanels = () => {
  /**
   * Given a panel, traverse all of it's references that could
   * be variation containers, and resolve them to the expected
   * cms content
   */
  function resolvePanelVariations(panel) {
    const result = useCmsVariant<CMS.CancelFlowPanel>(panel)
    // Prompts could be null, in the event this is an action panel,
    // set them to an empty array.
    result.cancelFlowPrompts = result.cancelFlowPrompts || []
    // Iterate over every prompt, resolving
    // - the panel itself
    // - the default cta
    // - the default destination
    // - the optional canReceiveCreditDestination

    /**
     * NOTE: Intentional Breaking of Hook Rules
     * (https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level)
     * says not to call hooks inside loops, conditionals, or nested functions.
     * this is so ensure that hooks are called in the same order, each time a
     * component renders. This allows react to correctly preserve the state of
     * hooks.
     * You can confidently use a hook within a map/loop if you are sure that
     * the loop is justified and guarantees the order. Given the data coming
     * from contentful at build time will not change, we know the array of
     * objects will not mutate between renders and thus this invocation is safe.
     */
    result.cancelFlowPrompts = result.cancelFlowPrompts.map((prompt) => {
      const resolvedPrompt = useCmsVariant<CMS.CancelFlowPrompt>(prompt)
      resolvedPrompt.defaultCta = useCmsVariant<CMS.LongCTA>(
        resolvedPrompt.defaultCta
      )
      resolvedPrompt.defaultDestination = useCmsVariant<CMS.CancelFlowPanel>(
        resolvedPrompt.defaultDestination
      )
      // This is an optional field in contentful, and could be null
      resolvedPrompt.canReceiveCreditDestination = useCmsVariant<CMS.CancelFlowPanel | null>(
        resolvedPrompt.canReceiveCreditDestination
      )
      return resolvedPrompt
    })
    return result
  }

  /**
   * Execute the query for the panels, we want to fetch the root panel
   * in isolation, as this might not be '/' or 'home' for it's route if
   * we are running an experiment
   */
  const data: QueryData = useStaticQuery(graphql`
    query CancelFlowPanels {
      root: contentfulMultiStageFlow(uniqueId: { eq: "cancel_flow" }) {
        ...MultiStageFlow
      }
      areYouSure: contentfulMultiStageFlow(uniqueId: { eq: "are-you-sure" }) {
        ...MultiStageFlow
      }
      panels: allContentfulCancelFlowPanel {
        nodes {
          ...CancelFlowPanel
        }
      }
    }
  `)

  // Resolve home panel variants
  const home = resolvePanelVariations(data.root.entryPoint)

  const areYouSure = resolvePanelVariations(data.areYouSure.entryPoint)
  areYouSure.panelId = 'are-you-sure'
  // Resolve each panels variants.
  const panels = data.panels.nodes.map((p) => resolvePanelVariations(p))
  // Validation
  // Validate all the panel routes exist
  const panelRoutes = []
  panels.forEach((panel) =>
    panel.cancelFlowPrompts?.map((prompt) => {
      if (prompt.defaultDestination.panelId) {
        if (prompt.defaultDestination?.panelId) {
          panelRoutes.push(prompt.defaultDestination?.panelId)
        }

        if (prompt.canReceiveCreditDestination?.panelId) {
          panelRoutes.push(prompt.canReceiveCreditDestination?.panelId)
        }
      }
    })
  )
  // Combine all of the panelRoutes and the required routes into a unique array
  const ExpectedRoutes = [...new Set(panelRoutes), ...REQUIRED_ROUTES]

  // Collect all of the Available Routes from the Contentful payload
  const availableRoutes = panels.map((panel) => panel.panelId)

  // Iterate over the expected routes and confirm they all exist within the available routes.
  const hasAllRequiredRoutes = ExpectedRoutes.every((route) =>
    availableRoutes.includes(route)
  )
  // Throw an error if this isn't true.
  if (!hasAllRequiredRoutes) {
    throw new Error('Required Cancel Flow Routes Were Missing')
  }

  const navigation = panels.filter(
    (panel) =>
      panel.cancelFlowPrompts.length > 0 &&
      panel.contentful_id !== home.contentful_id &&
      panel.contentful_id !== areYouSure.contentful_id
  )
  const action = panels.filter(
    (panel) =>
      panel.cancelFlowPrompts.length === 0 &&
      panel.contentful_id !== home.contentful_id &&
      panel.contentful_id !== areYouSure.contentful_id
  )

  return {
    home,
    areYouSure,
    navigation,
    action,
  }
}

export default useCmsCancelPanels
