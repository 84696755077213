import { Grid } from '@chakra-ui/core'
import { rem } from 'design'
import { ModalHeader } from 'design/components/Modal/Modal'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import PanelLayout, {
  ModalItemSmall,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import type { GenericCancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'
import StopMySubscriptionButton from '~/components/CancelFlowPanels/StopMySubscriptionButton/StopMySubscriptionButton'
/**
 * This is a generic presentation component shared across
 * multiple views of the cancel flow
 */
const GenericCancelPanel: React.FC<GenericCancelPanelProps> = ({
  panel,
  modalOptions,
  showStopOption = true,
}) => {
  // If we have < 5 calls to action, then we render this as two rows
  const tabletColumns = modalOptions.length < 5 ? '1fr' : '1fr 1fr'
  return (
    <PanelLayout>
      <ModalHeader pb={rem(8)} variant="bare">
        {panel.headline}
      </ModalHeader>
      <Body pb={rem(16)}>{panel.subhead}</Body>
      <Grid
        gap={rem(16)}
        gridTemplateColumns={{ base: '1fr', tablet: tabletColumns }}
      >
        {modalOptions.map((option, index) => (
          <ModalItemSmall
            data-attr-index={index}
            data-attr-index-of={modalOptions.length}
            data-attr-target-intent={option.analyticsIntent}
            data-attr-target-save-component={option.targetSaveComponent}
            data-cy={option.cypressId}
            key={index}
            onClick={() => option.clickHandler()}
          >
            {option.text}
          </ModalItemSmall>
        ))}
        {showStopOption ? <StopMySubscriptionButton /> : null}
      </Grid>
    </PanelLayout>
  )
}

export default GenericCancelPanel
