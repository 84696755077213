import React from 'react'
import type { NavigateFn } from '@reach/router'
import type { CancelSettings, Subscription } from '~/bb-api/schemata'
import type { CMS } from '~/cms/types'
import { TEST_ID } from '~/constants/cypress'
import { CancelPanelOptions } from '~/components/CancelFlowPanels/CancelFlowPanel.types'

export default function useUIPrompts(
  prompts: Array<CMS.CancelFlowPrompt>,
  cancelSettings: CancelSettings,
  subscription: Subscription,
  navigationFunction: NavigateFn
): Array<CancelPanelOptions> {
  return React.useMemo(() => {
    // Escape Hatch - If the API hasn't provided cancel settings yet.
    if (!cancelSettings) return []
    return prompts.map((prompt) => {
      // Determine Destination
      // Pivot the destination if they have a credit available
      // and a credit destination was provided.
      let destination =
        cancelSettings.canReceiveCredit && prompt.canReceiveCreditDestination
          ? prompt.canReceiveCreditDestination.panelId
          : prompt.defaultDestination.panelId

      // Intent
      // Intent maps to the destination panel's save component
      let targetSaveComponent: CancelPanelOptions['targetSaveComponent'] =
        cancelSettings.canReceiveCredit && prompt.canReceiveCreditDestination
          ? prompt.canReceiveCreditDestination.cancelSaveComponent
          : prompt.defaultDestination.cancelSaveComponent

      // AnalyticsIntent
      const analyticsIntent: CancelPanelOptions['analyticsIntent'] =
        cancelSettings.canReceiveCredit && prompt.canReceiveCreditDestination
          ? prompt.canReceiveCreditDestination.analyticsIntent
          : prompt.defaultDestination.analyticsIntent

      // If a member is currently receiving chef's value box,
      // Don't attempt to present them with that offer, rather diver them to are-you-sure

      // NOTE: This is a very tight coupling to pivot on a particular subscription box.
      // As we add additional box types: eg: 99$ box we will likely have to revisit this conditional.
      // We could mitigate this in the future by expanding the `CancelFlowPrompt` in the future to have
      // Different path ways based on box types.

      if (
        prompt.defaultDestination.panelId === 'offer-value-box' &&
        subscription.box.type === 'chef_value'
      ) {
        destination = 'are-you-sure'
        targetSaveComponent = 'Confirm Cancellation'
      }

      // If there was no saveComponent then this is a navigation panel
      targetSaveComponent = targetSaveComponent || 'Navigation'

      // CTA Text
      const text = prompt.defaultCta.ctaText

      // Click Handler
      // the CTA text is passed in as state, and used as the 'reason'
      // in the payload when canceling
      const clickHandler = () =>
        navigationFunction(`/${destination}`, { state: { text: text } })

      // Test ID
      const cypressId = TEST_ID.CANCEL_FLOW_OPTION

      return {
        clickHandler,
        cypressId,
        text,
        targetSaveComponent,
        analyticsIntent,
      }
    })
  }, [cancelSettings, navigationFunction, prompts, subscription.box.type])
}
